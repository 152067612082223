<template>
  <div id="app">
    <div v-if="showOverlay" class="fm-overlay" />
    <!-- navbar -->
    <TheNavbar @toggle-overlay="(e) => showOverlay = e" />

    <!-- main -->
    <main>
      <!-- hero section -->
      <TheHero />
    </main>

    <!-- footer -->
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue';
import TheHero from '@/components/TheHero.vue';

export default {
  name: 'App',
  components: {
    TheNavbar,
    TheHero,
  },
  data() {
    return {
      showOverlay: false,
    };
  },
};
</script>
