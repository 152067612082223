<template>
  <button :class="classes">
      <slot />
  </button>
</template>

<script>
export default {
  props: {
    /**
     * inverts the component type
     */
    isInvert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'fm-button': true,
        'fm-button--invert': this.isInvert,
      };
    },
  },
};
</script>
