<template>
    <details class="fm-menu">
        <summary class="fm-menu__label">
            {{ item.title }}

            <SVGArrowDown />
        </summary>

        <div class="fm-menu__dropdown">
          <UICard>
            <nav class="fm-menu__nav">
              <a
                v-for="(dropItem, i) in item.children"
                :key="i"
                :href="dropItem.route"
                class="fm-menu__item"
              >
                <component
                    v-if="dropItem.icon"
                    :is="`SVGIcon${capitalize(dropItem.icon)}`"
                />
                <h5>{{ dropItem.title }}</h5>
              </a>
            </nav>
          </UICard>
        </div>
    </details>
</template>

<script>
import SVGArrowDown from '@/assets/images/icons/icon-arrow-down.svg';

import UICard from '@/components/ui/UICard.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    SVGArrowDown,
    SVGIconTodo: () => import('@/assets/images/icons/icon-todo.svg'),
    SVGIconCalendar: () => import('@/assets/images/icons/icon-calendar.svg'),
    SVGIconReminders: () => import('@/assets/images/icons/icon-reminders.svg'),
    SVGIconPlanning: () => import('@/assets/images/icons/icon-planning.svg'),
    UICard,
  },
  methods: {
    capitalize(value) {
      if (!value) return '';
      const returnValue = value.toString();
      return returnValue.charAt(0).toUpperCase() + returnValue.slice(1);
    },
  },
};
</script>
