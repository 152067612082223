<template>
  <nav class="fm-navbar">
      <a aria-label="Home" href="/">
        <SVGLogo />
      </a>

      <!-- menu (desktop) -->
      <ul class="fm-navbar__menu list-bare d-none d-xl-flex">
        <li v-for="(item, i) in menuItems" :key="i" class="fm-navbar__menu-item">
          <UIMenu v-if="item.hasOwnProperty('children')" :item="item" />

          <a v-else :href="item.route">{{ item.title }}</a>
        </li>
      </ul>

      <ul class="fm-navbar__actions list-bare d-none d-xl-flex">
        <li class="fm-navbar__menu-item">
          <a href="#">Login</a>
        </li>
        <UIButton>Register</UIButton>
      </ul>

      <button aria-label="Open drawer" class="d-xl-none" @click="drawer = true">
        <SVGMenu />
      </button>

      <!-- mobile drawer -->
      <TheDrawer v-model="drawer" class="d-xl-none" />
  </nav>
</template>

<script>
import SVGLogo from '@/assets/logo.svg';
import SVGMenu from '@/assets/images/icons/icon-menu.svg';

import TheDrawer from '@/components/TheDrawer.vue';
import UIMenu from '@/components/ui/UIMenu.vue';
import UIButton from '@/components/ui/UIButton.vue';

export default {
  name: 'App',
  components: {
    SVGLogo,
    SVGMenu,
    TheDrawer,
    UIMenu,
    UIButton,
  },
  data() {
    return {
      drawer: false,
      menuItems: [
        {
          title: 'features',
          children: [
            { title: 'todo list', icon: 'todo', route: '#' },
            { title: 'calendar', icon: 'calendar', route: '#' },
            { title: 'reminders', icon: 'reminders', route: '#' },
            { title: 'planning', icon: 'planning', route: '#' },
          ],
        },
        {
          title: 'company',
          children: [
            { title: 'history', icon: null, route: '#' },
            { title: 'our team', icon: null, route: '#' },
            { title: 'blog', icon: null, route: '#' },
          ],
        },
        { title: 'careers', route: '#' },
        { title: 'about', route: '#' },
      ],
    };
  },
  watch: {
    drawer(val) {
      this.$emit('toggle-overlay', val);
    },
  },
};
</script>
