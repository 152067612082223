<template>
  <div :class="classes" aria-modal="true">
      <div class="fm-drawer__menu">
        <button aria-label="Close drawer" class="fm-drawer__close" @click="$emit('input', false)">
            <SVGCloseMenu />
        </button>

        <nav class="fm-drawer__nav-menu">
            <ul class="fm-drawer__nav-menu-list list-bare">
                <li v-for="(item, i) in menuItems" :key="i" class="fm-drawer__nav-items">
                <UIMenu v-if="item.hasOwnProperty('children')" :item="item" />

                <a v-else :href="item.route">{{ item.title }}</a>
                </li>
            </ul>

            <ul class="fm-drawer__nav-actions list-bare">
                <li class="fm-drawer__nav-actions-item">
                    <a href="#">login</a>
                </li>
                <UIButton>Register</UIButton>
            </ul>
        </nav>
      </div>
  </div>
</template>

<script>
import SVGCloseMenu from '@/assets/images/icons/icon-close-menu.svg';

import UIMenu from '@/components/ui/UIMenu.vue';
import UIButton from '@/components/ui/UIButton.vue';

export default {
  props: {
    /**
     * controls whether the component is extended or not.
     */
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SVGCloseMenu,
    UIMenu,
    UIButton,
  },
  data() {
    return {
      menuItems: [
        {
          title: 'features',
          children: [
            { title: 'todo list', icon: 'todo', route: '#' },
            { title: 'calendar', icon: 'calendar', route: '#' },
            { title: 'reminders', icon: 'reminders', route: '#' },
            { title: 'planning', icon: 'planning', route: '#' },
          ],
        },
        {
          title: 'company',
          children: [
            { title: 'history', icon: null, route: '#' },
            { title: 'our team', icon: null, route: '#' },
            { title: 'blog', icon: null, route: '#' },
          ],
        },
        { title: 'careers', route: '#' },
        { title: 'about', route: '#' },
      ],
    };
  },
  computed: {
    classes() {
      return {
        'fm-drawer': true,
        'fm-drawer--extended': this.value,
      };
    },
  },
};
</script>
