<template>
    <section class="hero container--xl">
        <div class="hero__image">
            <picture>
                <source srcset="@/assets/images/hero-desktop.png" media="(min-width: 1440px)">

                <img src="@/assets/images/hero-mobile.png" alt="Man with laptop">
            </picture>
        </div>

        <article class="hero__content">
            <h1>Make <span class="d-xl-block">remote work</span></h1>

            <p>
                Get your team in sync, no matter your location.
                Streamline processes, create team rituals, and
                watch productivity soar.
            </p>

            <UIButton is-invert>Learn more</UIButton>

            <footer class="hero__content-footer">
                <ul class="hero__clients list-bare">
                    <li class="hero__client">
                        <SVGClientDataBiz />
                    </li>
                    <li class="hero__client">
                        <SVGClientAudiophile />
                    </li>
                    <li class="hero__client">
                        <SVGClientMeet />
                    </li>
                    <li class="hero__client">
                        <SVGClientMaker />
                    </li>
                </ul>
            </footer>
        </article>
    </section>
</template>

<script>
import SVGClientDataBiz from '@/assets/images/clients/client-databiz.svg';
import SVGClientAudiophile from '@/assets/images/clients/client-audiophile.svg';
import SVGClientMeet from '@/assets/images/clients/client-meet.svg';
import SVGClientMaker from '@/assets/images/clients/client-maker.svg';

import UIButton from '@/components/ui/UIButton.vue';

export default {
  components: {
    SVGClientDataBiz,
    SVGClientAudiophile,
    SVGClientMeet,
    SVGClientMaker,
    UIButton,
  },
};
</script>

<style lang="scss" scoped>
.hero {
    $block: &;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: map-get($spacers, 8) 0;

    @include for-breakpoint('xl-only') {
        flex-wrap: nowrap;
    }

    &__image {
        width: 100%;
        img {
            display: block;
            width: 100%;
        }

        @include for-breakpoint('xl-only') {
             order: 2;
             max-width: 40%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: map-get($spacers, 6);
        width: 100%;
        padding: map-get($spacers, 12) map-get($spacers, 4);

        @include for-breakpoint('xl-only') {
             order: 1;
             align-items: flex-start;
             justify-content: flex-end;
             gap: map-get($spacers, 12);
             padding: 0 map-get($spacers, 10);
        }

        h1 {
            color: map-get($color-neutral, 'base');
            font-family: $font-primary;
            font-size: min(map-get($typescale, 'h5'), 8vw);
            // font-size: map-get($typescale, 'h5');
            font-weight: map-get($font-weights, 'bold');

            @include for-breakpoint('xl-only') {
                font-size: map-get($typescale, 'h1');
            }
        }

        p {
            color: map-get($color-neutral, 'gray');
            font-family: $font-primary;
            font-size: map-get($typescale, 'body-2');
            font-weight: map-get($font-weights, 'bold');
            line-height: map-get($spacers, 6);
            word-spacing: map-get($spacers, 1);
            text-align: center;

            @include for-breakpoint('xl-only') {
                width: min(27rem, 90vw);
                font-size: map-get($typescale, 'body-1');
                font-weight: map-get($font-weights, 'medium');
                line-height: map-get($spacers, 7);
                word-spacing: unset;
                text-align: left;
            }
        }

        &-footer {
            margin-top: map-get($spacers, 4);

            @include for-breakpoint('xl-only') {
                width: 80%;
            }
        }

        #{$block}__clients {
            display: flex;
            text-align: center;

            #{$block}__client {
                flex: 1 1 25%; // 4 items

                svg {
                    width: 80%;
                    height: 80%;
                }
            }
        }

        .fm-button--invert {
            font-size: map-get($typescale, 'body-1');
            padding: map-get($spacers, 4) map-get($spacers, 5);
        }
    }
}
</style>
